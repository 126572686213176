import { default as _91challengeId_93bGtXQnTvUhMeta } from "/opt/build/repo/pages/play/[company]/[companyId]/[program]/challenge/[challengeId].vue?macro=true";
import { default as indexCWcLkSWdpcMeta } from "/opt/build/repo/pages/play/[company]/[companyId]/[program]/in-app/index.vue?macro=true";
import { default as indexHDezqupsOAMeta } from "/opt/build/repo/pages/play/[company]/[companyId]/[program]/index.vue?macro=true";
import { default as indexxLTtHlXQ8aMeta } from "/opt/build/repo/pages/play/[company]/[companyId]/[program]/new-password/index.vue?macro=true";
import { default as _91rewardId_93MlEurOVyd9Meta } from "/opt/build/repo/pages/play/[company]/[companyId]/[program]/reward/[rewardId].vue?macro=true";
import { default as embeddedo4g9h2SKuCMeta } from "/opt/build/repo/pages/play/[company]/[companyId]/[program]/shopify/embedded.vue?macro=true";
import { default as index7OMSQhMETRMeta } from "/opt/build/repo/pages/play/[company]/[companyId]/unsubscribe/index.vue?macro=true";
import { default as _91companyId_93sMvXTFFdUGMeta } from "/opt/build/repo/pages/play/[company]/[companyId].vue?macro=true";
import { default as accountjVN2hMG1hSMeta } from "/opt/build/repo/pages/play/shopify/account.vue?macro=true";
import { default as referralz5BDMiJcp0Meta } from "/opt/build/repo/pages/play/shopify/referral.vue?macro=true";
import { default as indexIzNpVjQvvEMeta } from "/opt/build/repo/pages/play/tiktok-oauth/index.vue?macro=true";
export default [
  {
    name: "play-company-companyId",
    path: "/play/:company()/:companyId()",
    meta: _91companyId_93sMvXTFFdUGMeta || {},
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId].vue"),
    children: [
  {
    name: "play-company-companyId-program-challenge-challengeId",
    path: ":program()/challenge/:challengeId()",
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/[program]/challenge/[challengeId].vue")
  },
  {
    name: "play-company-companyId-program-in-app",
    path: ":program()/in-app",
    meta: indexCWcLkSWdpcMeta || {},
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/[program]/in-app/index.vue")
  },
  {
    name: "play-company-companyId-program",
    path: ":program()",
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/[program]/index.vue")
  },
  {
    name: "play-company-companyId-program-new-password",
    path: ":program()/new-password",
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/[program]/new-password/index.vue")
  },
  {
    name: "play-company-companyId-program-reward-rewardId",
    path: ":program()/reward/:rewardId()",
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/[program]/reward/[rewardId].vue")
  },
  {
    name: "play-company-companyId-program-shopify-embedded",
    path: ":program()/shopify/embedded",
    meta: embeddedo4g9h2SKuCMeta || {},
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/[program]/shopify/embedded.vue")
  },
  {
    name: "play-company-companyId-unsubscribe",
    path: "unsubscribe",
    meta: index7OMSQhMETRMeta || {},
    component: () => import("/opt/build/repo/pages/play/[company]/[companyId]/unsubscribe/index.vue")
  }
]
  },
  {
    name: "play-shopify-account",
    path: "/play/shopify/account",
    meta: accountjVN2hMG1hSMeta || {},
    component: () => import("/opt/build/repo/pages/play/shopify/account.vue")
  },
  {
    name: "play-shopify-referral",
    path: "/play/shopify/referral",
    meta: referralz5BDMiJcp0Meta || {},
    component: () => import("/opt/build/repo/pages/play/shopify/referral.vue")
  },
  {
    name: "play-tiktok-oauth",
    path: "/play/tiktok-oauth",
    meta: indexIzNpVjQvvEMeta || {},
    component: () => import("/opt/build/repo/pages/play/tiktok-oauth/index.vue")
  }
]